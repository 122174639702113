import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { useClient } from '../../providers/ClientProvider'
import DataTable from '../../../../../../components/DataTable'
import { nameEntity, nameSource } from './domain/info'
import { headers } from './domain/headers'
import { FaPrint } from 'react-icons/fa'
import moment from 'moment'
import api from '../../../../../../services/api'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'
import Modal from '../../../../../../components/Modal'
import { FormOrderHistory } from '../../components/OrderHistory'
import ReactTooltip from 'react-tooltip'
import { ScrollTextIcon } from '../../../../../../assets/image/svg/ScrollTextIcon'

export const BudgetTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [id, setId] = useState()
  const [createModalHistory, setCreateModalHistory] = useState(false)
  const [order, setOrder] = useState(null)
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  useEffect(() => {
    if (initialValues?.client.id) {
      setId(initialValues.client.id)
    }
  }, [initialValues])

  const handleClose = () => {
    setCreateModalHistory(false)
    setOrder(null)
  }

  return (
    <Container>
      {id && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          notHasChildren
          headers={headers}
          searchParameters={[{ client_id: id, situation: 'PENDENTE' }]}
          customHeaders={[
            {
              field: 'date',
              name: 'Data',
              sortable: false,
              element: item =>
                item.date && (
                  <span>
                    {moment(item.date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                )
            },
            {
              field: 'delivery_date',
              name: 'Data',
              sortable: false,
              element: item =>
                item.delivery_date && (
                  <span>
                    {moment(item.delivery_date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                )
            },
            {
              field: 'actions',
              name: 'Ações',
              sortable: false,
              element: item => {
                const lastOrderHistory =
                  item.orderHistories?.[item?.orderHistories.length - 1]
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'end'
                    }}
                  >
                    <a
                      href={`https://app-v2.multfluxosistema.com.br/commercial/budgets/${
                        item.type?.toUpperCase() === 'VENDA'
                          ? 'sale'
                          : 'location'
                      }/view/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="fa fa-search"></span>
                    </a>
                    <a
                      href={`https://app-v2.multfluxosistema.com.br/commercial/budgets/${
                        item.type?.toUpperCase() === 'VENDA'
                          ? 'sale'
                          : 'location'
                      }/update/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="fa fa-edit"></span>
                    </a>
                    <a
                      onClick={() => {
                        activeLoading()
                        api
                          .get(
                            `/commercial/commercialBudget/orderNew/${item.id}`,
                            {
                              responseType: 'arraybuffer'
                            }
                          )
                          .then(response => {
                            const a = document.createElement('a')
                            const blob = new Blob([response.data], {
                              type: 'application/pdf'
                            })
                            const url = window.URL.createObjectURL(blob)
                            a.href = url
                            a.download = `Pedido-${initialValues?.person?.name}.pdf`
                            a.click()
                            disableLoading()
                          })
                          .catch(error => {
                            const errorResponse = String.fromCharCode.apply(
                              null,
                              new Uint8Array(error?.response?.data) as any
                            )
                            const message = errorResponse
                              ? JSON.parse(errorResponse)
                              : { message: 'Erro ao baixar pedido' }

                            addToast({
                              title: message?.message,
                              type: 'error'
                            })
                            disableLoading()
                          })
                      }}
                    >
                      <FaPrint />
                    </a>
                    <a
                      data-tip={true}
                      data-class="my-tooltip"
                      data-for={`my-tooltip-${item.id}`}
                      style={{ height: '19.98px' }}
                      onClick={() => {
                        setOrder(item)
                        setCreateModalHistory(true)
                      }}
                    >
                      <ScrollTextIcon />
                      {lastOrderHistory && (
                        <ReactTooltip
                          multiline={true}
                          place="top"
                          type="dark"
                          effect="float"
                          id={`my-tooltip-${item.id}`}
                        >
                          <p>
                            {lastOrderHistory.created_at} -{' '}
                            {lastOrderHistory.description}
                          </p>
                        </ReactTooltip>
                      )}
                    </a>
                  </div>
                )
              }
            }
          ]}
        />
      )}
      {createModalHistory && order && (
        <Modal
          onClickButtonCancel={handleClose}
          isOpenModal={createModalHistory}
          pageTitle={'Adicionar histórico'}
          Children={
            <FormOrderHistory
              client_id={id}
              order_id={order?.id}
              type={order?.type?.toLowerCase() === 'venda' ? 'V' : 'L'}
              handleClose={handleClose}
            />
          }
          styles={{
            padding: '30px',
            overflow: 'auto'
          }}
          sectionStyle={{
            minWidth: '100%'
          }}
        />
      )}
    </Container>
  )
}
