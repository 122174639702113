import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

type Segment = {
  id: number
  name: string
  cash_payment_only?: number | null
  can_deliver_another_address?: number | null
}
type TypesFormProps = {
  segment?: Segment
  typeForm: 'create' | 'update'
  isOpenInModal?: IsOpenInModalProps
}
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

export const FormSegment = ({
  segment,
  isOpenInModal,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<Segment>()

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    setDefaultValues({
      ...segment
    })
  }, [segment])

  const onSubmitForm = async (data: Segment) => {
    if (data.can_deliver_another_address) {
      data.can_deliver_another_address = Number(
        data.can_deliver_another_address
      )
    }
    if (data.cash_payment_only) {
      data.cash_payment_only = Number(data.cash_payment_only)
    }
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            parent_id: idParent
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(segment?.id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(segment?.id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="row">
          <div className="form-content col-md-3">
            <Input
              name="name"
              className="form-control"
              label="Nome"
              rules={{ required: true }}
            />
          </div>
          <div className="form-content col-md-3">
            <Select
              name="can_deliver_another_address"
              className="form-control"
              label="Pode ter a entrega em outro endereço?"
              rules={{ required: true }}
              blank
              options={[
                {
                  name: 'SIM',
                  value: 1
                },
                {
                  name: 'NÃO',
                  value: 0
                }
              ]}
            />
          </div>
          {!!isOpenInModal && (
            <div className="form-content col-md-3">
              <Select
                name="cash_payment_only"
                className="form-control"
                label="Somente pagamento a vista?"
                rules={{ required: true }}
                blank
                disabledSelect={false}
                options={[
                  {
                    name: 'SIM',
                    value: 1
                  },
                  {
                    name: 'NÃO',
                    value: 0
                  }
                ]}
              />
            </div>
          )}
        </div>
        <div className="form-actions right">
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </>
    </Form>
  )
}
