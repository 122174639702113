import React, { useCallback, useState, useEffect } from 'react'

import { Button, ModalContainer, Title } from './style'
import Form, { Select, Textarea } from '../../../../../../components/Form'
import CustomDataTable from '../../../../../../components/CustomDataTable'
import api from '../../../../../../services/api'
import { Date as Datepicker } from '../../../../../../components/Form/date'
import moment from 'moment'
import { DATE_MASK_ISO_WITHOUT_TIME } from '../../../../../../common/constants'
import { useToast } from '../../../../../../hooks/toast'
import { Loading } from '../../../../../../components/Loading'

interface FormOrderHistoryProps {
  client_id: number
  order_id: number
  type: 'V' | 'L'
  handleClose: () => void
}

export const FormOrderHistory: React.FC<FormOrderHistoryProps> = ({
  client_id,
  order_id,
  type,
  handleClose
}) => {
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [orderHistory, setOrderHistory] = useState(null)
  const [appointmentsDateTime, setAppointmentsDateTime] =
    useState<Date | null>()
  const [situations, setSituations] = useState([])
  const [agents, setAgents] = useState([])
  const [hasAppointments, setHasAppointments] = useState('0')

  const loadSituations = useCallback(async () => {
    try {
      const responseSituations = await api.get(
        '/commercial/ordersHistoriesSituationsNew'
      )
      setSituations(
        responseSituations.data?.map((item: { name: string; id: number }) => ({
          name: item.name,
          value: item.id
        }))
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar as situações.'
      })
    }
  }, [addToast])

  const loadOrderHistory = useCallback(async () => {
    try {
      const responseOrderHistory = await api.get(
        `commercial/ordersHistoriesNew?order_id=${order_id}&type=${type}`
      )
      setOrderHistory({
        ...responseOrderHistory.data
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados do histórico.'
      })
    }
  }, [addToast, order_id, type])

  const loadAgents = useCallback(async () => {
    try {
      const responseAgents = await api.get(
        `/commercial/agents/optionsUser?type=${type}`
      )
      setAgents(
        responseAgents.data?.map(
          (item: { name: string; id: number; user_id: number }) => ({
            name: item.name,
            value: item.id,
            user_id: item.user_id
          })
        )
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar opções de usuários.'
      })
    }
  }, [addToast, type])

  useEffect(() => {
    loadSituations()
  }, [loadSituations])

  useEffect(() => {
    loadOrderHistory()
  }, [loadOrderHistory])

  useEffect(() => {
    loadAgents()
  }, [loadAgents])

  const onSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true)
      let date = null
      if (appointmentsDateTime) {
        date = moment(appointmentsDateTime).format(DATE_MASK_ISO_WITHOUT_TIME)
      }
      let time = null
      if (appointmentsDateTime) {
        time = moment(appointmentsDateTime).format('HH:mm')
      }
      let agent = null
      if (data.user_demanded_id) {
        agent = agents?.find(
          item => item.value === Number(data.user_demanded_id)
        )
      }

      const formData = {
        type: type,
        order_id: order_id,
        client_id,
        action: data.action,
        description: data.description,
        has_schedule: !!Number(hasAppointments),
        situation_id: Number(data.situation_id),
        appointment_date: date,
        appointment_time: time,
        appointment_action: data.appointment_action,
        appointment_description: data.action,
        user_demanded_id: agent?.user_id ? Number(agent.user_id) : null
      }
      try {
        await api.post('/commercial/ordersHistoriesNew/create', formData)
        addToast({
          type: 'success',
          title: 'Registro adicionado com sucesso'
        })
        if (handleClose) {
          handleClose()
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao criar o registro.'
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      addToast,
      agents,
      appointmentsDateTime,
      client_id,
      handleClose,
      hasAppointments,
      order_id,
      type
    ]
  )

  return (
    <>
      <Loading isActive={isLoading} />
      <ModalContainer>
        <Form onSubmit={onSubmit}>
          <div className="row mb-5">
            <div className="col-md-12">
              <Textarea
                label="Informação"
                name="description"
                style={{ minHeight: 100 }}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <Textarea
                label="Ação"
                name="action"
                style={{ minHeight: 100 }}
                rules={{ required: true }}
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-6">
              <Select
                name="situation_id"
                label="Situação"
                options={situations}
                rules={{ required: true }}
                blank
                controlled
                disabledSelect={false}
              />
            </div>
            <div className="col-md-6">
              <Select
                name="has_schedule"
                label="Deseja realizar um agendamento?"
                rules={{ required: true }}
                blank
                disabledSelect={false}
                options={[
                  {
                    name: 'SIM',
                    value: 1
                  },
                  {
                    name: 'NÃO',
                    value: 0
                  }
                ]}
                value={hasAppointments}
                onChange={event => {
                  setHasAppointments(event.target.value)
                }}
                controlled
              />
            </div>
          </div>

          {hasAppointments === '1' && (
            <div className="row mb-5">
              <div className="col-md-4">
                <Datepicker
                  label="Data/hora de agendamento"
                  className="form-control"
                  rules={{ required: false }}
                  name="appointment_date"
                  selected={appointmentsDateTime}
                  onChange={event => {
                    setAppointmentsDateTime(event)
                  }}
                  controlled
                  showTimeSelect
                  customInput={null}
                  dateFormat="dd/MM/yyyy HH:mm"
                  onKeyDown={e => {
                    e.preventDefault()
                  }}
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="appointment_action"
                  label="Ação"
                  rules={{ required: true }}
                  blank
                  disabledSelect={false}
                  options={[
                    {
                      name: 'EMAIL',
                      value: 'email'
                    },
                    {
                      name: 'LIGAÇÃO',
                      value: 'call'
                    },
                    {
                      name: 'MENSAGEM',
                      value: 'message'
                    },
                    {
                      name: 'VISITA',
                      value: 'visit'
                    }
                  ]}
                  controlled
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="user_demanded_id"
                  label="Quem"
                  rules={{ required: true }}
                  blank
                  disabledSelect={false}
                  options={agents}
                  controlled
                />
              </div>
            </div>
          )}
          <div className="right">
            <Button type="submit" className="btn dark btn-sm bold uppercase">
              Salvar
            </Button>
          </div>
        </Form>

        <Title>Histórico</Title>
        <CustomDataTable
          headers={[
            {
              name: 'Data/Hora',
              field: 'actions',
              sortable: false,
              custom: true
            },
            { name: 'Informação', field: 'description', sortable: false },
            { name: 'Ação', field: 'action', sortable: false },
            {
              name: 'Situação',
              field: 'situation_id',
              sortable: false,
              custom: true
            },
            {
              name: 'Agendamento',
              field: 'schedule',
              sortable: false,
              custom: true
            }
          ]}
          customItems={orderHistory?.history || []}
          customHeaders={[
            {
              name: 'Data/Hora',
              field: 'actions',
              sortable: false,
              element: item => {
                if (!item) {
                  return <p></p>
                }
                return <p>{item.created_at}</p>
              }
            },
            {
              name: 'Situação',
              field: 'situation_id',
              sortable: false,
              element: item => {
                if (!item?.situation?.name) {
                  return <p></p>
                }
                return <p>{item.situation.name}</p>
              }
            },
            {
              name: 'Agendamento',
              field: 'schedule',
              sortable: false,
              element: item => {
                const appointments = item?.appointments?.[0]
                if (!appointments) {
                  return <p></p>
                }
                return (
                  <p style={{ display: 'flex', justifyContent: 'start' }}>
                    {appointments.date} {appointments.time} -{' '}
                    {appointments.user.name}
                  </p>
                )
              }
            }
          ]}
        />
      </ModalContainer>
    </>
  )
}
