export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  {
    name: 'Pode ter a entrega em outro endereço?',
    field: 'can_deliver_another_address',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
export const subSegmentHeaders = [
  { name: 'Nome', field: 'name', sortable: true },
  {
    name: 'Pode ter a entrega em outro endereço?',
    field: 'can_deliver_another_address',
    sortable: true,
    custom: true
  },
  {
    name: 'Somente pagamento a vista?',
    field: 'cash_payment_only',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
