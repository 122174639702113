import React, {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useCallback,
  ReactElement
} from 'react'
import {
  Controller,
  RegisterOptions,
  useForm,
  UseFormRegister
} from 'react-hook-form'

import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { genericMaskWithTwoZero } from '../../utlis/mask'
import {
  ContainerCheckbox,
  Contanier,
  Error,
  SelectContanier,
  TextAreaContanier
} from './styles'

export default function Form({
  defaultValues,
  children,
  onSubmit,
  hasErrors
}: any) {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({ defaultValues, shouldUnregister: true })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  if (hasErrors) hasErrors(errors)

  const registeredField = useCallback(
    (child: ReactElement) => {
      if (child.props.controlled) {
        child.props.value && setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.fullControlled) {
        setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.price) {
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  value: genericMaskWithTwoZero(field.value),
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register,
          errors,
          key: child.props.name
        }
      })
    },
    [control, errors, register, setValue]
  )

  const buildChildren = useCallback(
    (children: ReactElement, key = 0): any => {
      if (Array.isArray(children)) {
        return children.map((child: ReactElement, index) => {
          return buildChildren(child, index)
        })
      }

      if (children?.props?.children) {
        const childCopy = React.cloneElement(children, {
          key,
          children: buildChildren(children.props.children)
        })
        return childCopy
      }
      return children?.props?.name ? registeredField(children) : children
    },
    [registeredField]
  )

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {buildChildren(children)}
    </form>
  )
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  hasError?: any
  errors?: any
  label?: string
  labelError?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  icon?: React.ComponentType<IconBaseProps>
}

export function Input({
  register,
  name,
  label,
  labelError,
  icon: Icon,
  rules,
  hasError,
  errors,
  ...rest
}: InputProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <Contanier erro={error}>
      {Icon && <Icon size={20} />}
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <input {...(register && register(name, rules))} {...rest} />
        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${labelError || label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Contanier>
  )
}

export function Checkbox({
  register,
  name,
  label,
  labelError,
  rules,
  hasError,
  errors,
  ...rest
}: InputProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <ContainerCheckbox erro={error}>
      <div>
        <input
          {...(register && register(name, rules))}
          {...rest}
          type="checkbox"
        />
        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${labelError || label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
    </ContainerCheckbox>
  )
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  register?: UseFormRegister<any>
  options: Array<{
    value: string | number
    name: string | number
  }>
  name: string
  label?: string
  rules?: RegisterOptions
  errors?: any
  controlled?: boolean
  fullControlled?: boolean
  blank?: boolean
  disabledSelect?: boolean
}

export function Select({
  register,
  options,
  name,
  label,
  rules,
  errors,
  blank,
  disabledSelect = true,
  ...rest
}: SelectProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <SelectContanier erro={error}>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <select {...(register && register(name, rules))} {...rest}>
          {blank && (
            <option key={0} value="" disabled={disabledSelect} selected>
              selecione
            </option>
          )}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </SelectContanier>
  )
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  errors?: any
  label?: string
}

export function Textarea({
  register,
  name,
  label,
  rules,
  errors,
  ...rest
}: TextareaProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error

  return (
    <TextAreaContanier erro={error}>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <textarea {...(register && register(name, rules))} {...rest} />
      </div>
    </TextAreaContanier>
  )
}
