import styled from 'styled-components'

export const Container = styled.div``

export const ModalContainer = styled.div``

export const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin: 10px 0 5px;
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 60px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
`

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

export const Button = styled.button`
  background-color: #555;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #444;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`

export const Th = styled.th`
  background: #f4f4f4;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`

export const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`
