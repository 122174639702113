import React, { useEffect, useMemo, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { Date as DatePicker } from '../../../../components/Form/date'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { BRL } from '../../../../utlis/mask'
import moment from 'moment'
import {
  DATE_MASK_LOCALE_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../../../common/constants'
import { FilterContaier } from './styles'
import { Select } from '../../../../components/Form'
import api from '../../../../services/api'

type Company = {
  id: number
  name: string
}

type Bank = {
  id: number
  nome: string
  empresa_id: number
}

const InvestmentsList = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [company, setCompany] = useState('')
  const [companies, setCompanies] = useState<Company[]>([])
  const [banks, setBanks] = useState<Bank[]>([])
  const [bankAccountId, setBankAccountId] = useState<string>('')
  const [parameters, setParameters] = useState<any>([])
  const searchParams = useMemo(() => {
    return [{ ...parameters[0], operation: 'A' }]
  }, [parameters])

  const loadCompanies = async () => {
    const { data } = await api.get('financial/configurations')
    const companies = data?.length
      ? data.filter((item: { id: number }) => item.id !== 2)
      : []
    setCompanies(companies)
  }
  const loadBanks = async () => {
    const { data } = await api.get('financial/bankAccountsOld')
    setBanks(data)
  }

  useEffect(() => {
    loadCompanies()
    loadBanks()
  }, [])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <FilterContaier>
        <div>
          <div className="col-md-3">
            <label htmlFor="company">Empresa</label>
            <select
              className="form-control"
              id="company"
              value={company}
              onChange={({ target }) => setCompany(target.value)}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              {companies?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <Select
              label="Conta Bancária"
              className="form-control"
              name="bank_account_id"
              options={banks
                .filter(bank =>
                  company.length ? bank.empresa_id === Number(company) : true
                )
                .map(bank => ({
                  value: String(bank.id),
                  name: bank.nome
                }))}
              onChange={e => setBankAccountId(e.target.value)}
              value={bankAccountId}
              blank
              defaultValue={''}
              fullControlled
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-3">
            <DatePicker
              className="form-control"
              name="start_date"
              label="Data de início"
              selected={startDate}
              onChange={e => {
                if (endDate) {
                  if (e > endDate) {
                    setEndDate(null)
                  }
                }
                setStartDate(e)
              }}
              controlled
            />
          </div>
          <div className="col-md-3">
            <DatePicker
              className="form-control"
              name="end_date"
              label="Data de término"
              selected={endDate}
              minDate={startDate}
              onChange={e => setEndDate(e)}
              controlled
            />
          </div>
        </div>
        <footer>
          <button
            onClick={() => {
              setCompany('')
              setBankAccountId('')
              setStartDate(null)
              setEndDate(null)
              setParameters([])
            }}
          >
            LIMPAR
          </button>
          <button
            onClick={() => {
              setParameters([
                {
                  company_id: company || undefined,
                  bank_account_id: bankAccountId || undefined,
                  startDate: startDate || undefined,
                  endDate: endDate || undefined
                }
              ])
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr />
      </FilterContaier>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        orderByField="id"
        orderBySort="DESC"
        notHasChildren
        headers={headers}
        onlyView
        searchParameters={searchParams}
        customHeaders={[
          {
            name: 'Data de Atualização',
            field: 'last_updated_at',
            sortable: true,
            element: item => {
              const findLastChild = item?.childInvestments?.sort(
                (
                  currentChildInvestment: { id: number },
                  nextChildInvestment: { id: number }
                ) => nextChildInvestment.id - currentChildInvestment.id
              )
              let updated_at = moment(item.date, DATE_MASK_LOCALE_WITH_TIME)
                .add(3, 'h')
                .format(DATE_MASK_LOCALE_WITHOUT_TIME)

              if (findLastChild?.[0]) {
                updated_at = moment(
                  findLastChild?.[0]?.date,
                  DATE_MASK_LOCALE_WITH_TIME
                )
                  .add(3, 'h')
                  .format(DATE_MASK_LOCALE_WITHOUT_TIME)
              }
              return <p>{updated_at}</p>
            }
          },
          {
            name: 'Data de Aplicação',
            field: 'created_at',
            sortable: true,
            element: item => {
              const created_at = moment(
                item.created_at,
                DATE_MASK_LOCALE_WITH_TIME
              )
                .add(3, 'h')
                .format(DATE_MASK_LOCALE_WITHOUT_TIME)

              return <p>{created_at}</p>
            }
          },
          {
            name: 'Saldo Atualizado',
            field: 'balance',
            sortable: true,
            element: item => {
              const findLastChild = item?.childInvestments?.sort(
                (
                  currentChildInvestment: { id: number },
                  nextChildInvestment: { id: number }
                ) => nextChildInvestment.id - currentChildInvestment.id
              )
              let balance = BRL(Number(item.balance)).format()
              if (
                findLastChild?.[0] &&
                (BRL(findLastChild?.[0]?.balance).intValue ||
                  BRL(findLastChild?.[0]?.balance).intValue === 0)
              ) {
                balance = BRL(Number(findLastChild?.[0]?.balance)).format()
              }
              return <p>{balance}</p>
            }
          },
          {
            name: 'Valor Aplicado',
            field: 'value',
            sortable: true,
            element: item => {
              const value = (item?.childInvestments || []).reduce(
                (acc: number, item: { value: number; operation: string }) => {
                  if (item.operation === 'CT') {
                    return (acc += Number(item.value))
                  }
                  return acc
                },
                Number(item.value)
              )
              return <p>{BRL(value).format()}</p>
            }
          }
        ]}
      />
    </Container>
  )
}

export default InvestmentsList
